.mainSection{
  display:flex;
  width: 100%;
  height: 100%;
  justify-content: center;  
  justify-self: center;
  max-width: 1100px;
}

.sectionWrapper{
  // padding-top:30px !important;
  max-width: 1200px !important;
  width: 100%;
  height:100%;
  position:relative;
  padding-top:0px;
  display:flex;
  flex-direction: column;
  align-items: center;  
}

.componentContainer{
  margin-top:30px;
  width:100%;
  max-width:900px;
  background: #fff;
  border-radius: 10px;
  // box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);
  display:block;
  min-height:200px;
}

.gridWrapper{
  width:100%;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;

  .sectionTitle{
    text-align:center;
    font-size: 19px;
    font-weight: 500;
    font-weight: bold;
  }
}

.gridWrapper > div{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.componentWrapper{
  margin-top:20px;
  width:100%;
}

.contentInfoWrapper{
  padding:7px;
  display:flex;
  flex-direction: column;
  height:100%;
  max-width:100% !important;
  min-width: 300px;
  width:100%;
  max-height: 400px;
  overflow-y:auto;
  border:1px solid #ebebeb;
  border-radius: 5px;
  // box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);


  fast-checkbox{
    margin-top:5px;
    margin-bottom:5px;
  }

  .contentInfo{
    padding-top:0px;
    font-size:15px;
    margin-bottom:0px;
  }

  .edits{
    padding:7px;
    font-size:15px;
  }

  .react-dropdown-select{
    margin-top:10px;
    border-radius: 5px;
    border:1px solid #384a60;
    outline-color: #000 !important;
  }

  .react-dropdown-select-dropdown{
    border-radius: 5px;  
    font-size:15px;
  }


}


.gorus-konular,.ulkeler-bolgeler{
  padding-left:20px;
  padding-right:20px;
  min-height:100% !important;
}

.gorus-konular{
  max-height: unset !important;
  height:auto !important;
  min-height:100% !important;
  overflow-y: unset !important;


}


.editSubject{
  margin-top:10px;
}

.mainSection2{
  .componentWrapper {
    margin-top:15px !important;
  }
}

.inputErrorSub{
  &:after{
    content:'';
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDI4Ni4wNTQgMjg2LjA1NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjg2LjA1NCAyODYuMDU0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojRTI1NzRDOyIgZD0iTTE0My4wMjcsMEM2NC4wNCwwLDAsNjQuMDQsMCwxNDMuMDI3YzAsNzguOTk2LDY0LjA0LDE0My4wMjcsMTQzLjAyNywxNDMuMDI3DQoJCWM3OC45OTYsMCwxNDMuMDI3LTY0LjAyMiwxNDMuMDI3LTE0My4wMjdDMjg2LjA1NCw2NC4wNCwyMjIuMDIyLDAsMTQzLjAyNywweiBNMTQzLjAyNywyNTkuMjM2DQoJCWMtNjQuMTgzLDAtMTE2LjIwOS01Mi4wMjYtMTE2LjIwOS0xMTYuMjA5Uzc4Ljg0NCwyNi44MTgsMTQzLjAyNywyNi44MThzMTE2LjIwOSw1Mi4wMjYsMTE2LjIwOSwxMTYuMjA5DQoJCVMyMDcuMjEsMjU5LjIzNiwxNDMuMDI3LDI1OS4yMzZ6IE0xNDMuMDM2LDYyLjcyNmMtMTAuMjQ0LDAtMTcuOTk1LDUuMzQ2LTE3Ljk5NSwxMy45ODF2NzkuMjAxYzAsOC42NDQsNy43NSwxMy45NzIsMTcuOTk1LDEzLjk3Mg0KCQljOS45OTQsMCwxNy45OTUtNS41NTEsMTcuOTk1LTEzLjk3MlY3Ni43MDdDMTYxLjAzLDY4LjI3NywxNTMuMDMsNjIuNzI2LDE0My4wMzYsNjIuNzI2eiBNMTQzLjAzNiwxODcuNzIzDQoJCWMtOS44NDIsMC0xNy44NTIsOC4wMS0xNy44NTIsMTcuODZjMCw5LjgzMyw4LjAxLDE3Ljg0MywxNy44NTIsMTcuODQzczE3Ljg0My04LjAxLDE3Ljg0My0xNy44NDMNCgkJQzE2MC44NzgsMTk1LjczMiwxNTIuODc4LDE4Ny43MjMsMTQzLjAzNiwxODcuNzIzeiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);    
    width:30px;
    height:30px;
    margin-left: 5px;
    margin-top: 0px;
    position: absolute;
    display: inline-block;
  }
}

.gorus-konular.gorus-konular-step2{
  // min-height: 340px !important;
  // max-height: 340px !important;
  overflow: hidden;
  overflow-y: auto !important;  
}

.react-dropdown-select-input{
  &::placeholder{
    font-size: 16px !important;
    font-family: 'Roboto',sans-serif !important;
  }
  width:100%;
  margin-top:5px;

}

span[class*="-ItemComponent"]{
  font-family: 'Roboto',sans-serif !important;
}
.react-dropdown-select-option-label{
  color:#384a60 !important;
  font-family: 'Roboto',sans-serif;
  font-size:16px;
}
.react-dropdown-select-option{
  background:#c2b7b1 !important;
  color:#384a60 !important;
}

.componentWrapper.step2-layer1{
  height: 300px !important;
}
.componentWrapper.step2-layer2{
  height: 240px !important;
}
.componentWrapper.step2-layer3{
  height: 300px !important;
  position: relative;

  .react-dropdown-select-dropdown{
    max-height: 140px;
  }

}
