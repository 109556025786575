.sliderID{
  display:flex;
  position: relative;
  width: 100%;
  height: 100%;
}
.sliderWrapper{
  position:relative;
  width:100%;
  display:flex;
  height:595px;
  background:url('../../../assets/images/kadinlar-banner-soso.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.sliderTitle{
  display:block;
  width: auto;
  max-width: 560px;
  text-align: center;
  background:rgba(59,69,89,0.6);
  color:#fff;
  padding-left:7px;
  padding-right:7px;
  font-size:3rem;
  font-weight: 700;
  margin-bottom:4px;
  font-family: 'Baskerville BT',serif;

  &:nth-of-type(2){
    margin-bottom:15px;
  }
}

.btnWatchVideo{
  position:absolute;
  display:flex;
  align-items: center;
  justify-content: space-around;
  bottom:55px;
  left:90px;
  width:375px;
  height: 75px;
  border:none;
  background:#4b4b4b;
  background-repeat: no-repeat;
  outline: none;
  padding:0px;
  cursor: pointer;
  color:#000000;
  font-family: 'Baskerville BT',serif;
  font-weight: 700;
  font-size:19px;
  // font-family: 'Baskervville', serif;

  &:hover{
    box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.25);
  }

  img{
    max-height: 27px;
  }
}

.sliderQuoteWrapper{
  position:absolute;
  right: 0px;
  bottom:80px;
}

.sliderQuote{
  width: 500px;
  height:40px;
  margin-bottom:15px;
  color:#ffffff;
  font-family: 'Baskerville BT',serif;
  font-weight: 500;
  font-size:48px;
}