.expertSectionWrapper{
  display: flex;
  background:#efedee;
  width: 100%;
  height:100vh;
  padding-top:191px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  

  .socialMedyaInfo {
    margin-left: 5px;
    margin-top: 1px;
    color: #666;
    max-width: calc(100% - 40px);
    width: 100%;
    display: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .webSectionTitle{
    margin-top:0px;
    margin-bottom:20px;
  }

  .expertSectionTitle{
    margin-top:0px;
    margin-bottom:10px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0 0 #384a60;
    color: #384a60;
    font-size: 21px;
    font-weight: bold;
  }  

  .expertWrapper{
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
    width: 100%;
    height: 100%;
    max-width: 1336px;
    padding: 30px;
    font-size: 1.3rem;
  }

  .expertContent{
    p{
      color:#384a60;
      font-size:17px;
      font-family: 'Baskerville BT',serif;
  
    }

    ul{

      margin-left:20px;

      li{
        color:#384a60;
        font-size:17px;
        font-family: 'Baskerville BT',serif;   
      }
    }
    
  }


  .expertTitle{
    color:#384a60;
    font-size:24px;
    font-family: 'Baskerville BT',serif;
  }

  .ulexpertList{
    list-style: none;
    font-size:1.3rem;
    max-width: 200px;
    justify-self: center;

    li{
      margin-bottom: 10px;
      color:#384a60;
      font-weight: 500;
      font-size: 17px;
      font-family: 'Baskerville BT',serif;
      line-height: 17px;     
      text-align: center; 

      &.expertFilterTitle{
        color:#384a60;
        font-weight: 500;
        font-family: 'Baskerville BT',serif;
        line-height: 17px;    
        font-size: 17px;    

        .lastExpertName{
          color:#384a60;
          font-size:21px;
          font-family: 'Baskerville BT',serif;
          font-weight: bold;
          margin-bottom:5px;
          text-align:center;
          display: flex;
          justify-content: center;
          align-items: center;      
        }
        .lastExpertTitle{
          color:#384a60;
          font-family: 'Baskerville BT',serif;
          font-weight: normal;
          font-style: italic;
          font-size: 16px;
          min-height: 50px;
          text-align:center;
        }

        .lastExpertContactTitle{
          color:#3e4a5e;
          text-shadow: 0 0 #3e4a5e;          
          font-size:21px;
          font-family: 'Baskerville BT',serif;
          font-weight: bold;          
          margin-bottom:5px;
          text-align:center;
          display: flex;
          justify-content: center;
          align-items: center;      
        }        
    

      }

    }


    .expertFilterContent{
      .inputWrapper.web{
        div{
          &:first-of-type{
            width:100%;
          }
        }
      }
    }


  }

}
.expertListSticky{
  position: sticky;
  top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .editsfilter{
    margin-top:0px;
  }

  .editfiltericon {
    position: absolute;
    left: 14px;
    top: 8px;
  }

  .buttonwrapper{
    width: auto !important;

    .btnFilterexpert{
      &:hover{
        cursor:pointer;
        background: #FF004B;
      }
    }
  }

  .react-dropdown-select {
    position: relative;
    margin-top: 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #9b897c;
    outline-color: #000 !important;
    max-width: 300px;
    width: 100%;
    font-weight: 300;
    font-size: 15px;
    font-family: 'Roboto Condensed', sans-serif !important;
    background:#fff;
    
  }

  .react-dropdown-select-input{
    position:absolute;
    top:5px;
    width: 100%;

    ::placeholder{
      font-size:15px;
    }
  }

  div[class*="ContentComponent"]{
    padding-left:5px;
    padding-right: 5px;
  }
        

}

.expertCount{
  font-weight: bold;
}
.expertResultInfoText{
  font-family: 'Roboto',sans-serif;
  font-size:16px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;

  li{
    display:inline;

    a,span{
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #FF004B;
      text-decoration: none;
      // background-color: #fff;
      // border: 1px solid #ddd;     
      
      &:hover{
        color:#FF964B;
      }
    }

    &:first-child>a,&:first-child>span{
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;      
    }

    &:last-child>a,&:last-child>span{
      margin-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }    

  }
}
.lastExpertsContentWrapper{
  display:grid;
  // grid-template-columns: repeat(5,auto);
  // grid-template-columns: repeat(auto-fill, minmax(min(100px,200px), 1fr));
  grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
  grid-gap: 28px;
  padding-top:30px;




  .lastExpertCard{
    position: relative;





    .expertButtonWrapper{
      display:flex;
      justify-content: center;
    }

    .lastExpertDetailBtn{
      // margin-top:20px;
      font-family: 'Roboto Condensed',sans-serif;
      padding-top: 6.5px;
      padding-bottom: 6.5px;      

      &:hover{
        background-color:#FF004B;
        color:#fff;
        transition: all 0.3s ease-out;
        cursor:pointer;
      }

    }
    
  }

}




.expertProfileImg{
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.20);
  margin-bottom:25px;
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 50%;
  stroke: #FF004B;
}
.expertLoading{
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  p{
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;

  }
}

.expertSocialMedyaUl{
  list-style: none;

  li{
    margin-bottom:10px;
  }
}

.expertIntroUl{
  list-style: none;
  margin-left:0px !important;
  font-size: 17px;
  font-family: 'Roboto',sans-serif;


  li{
    display:grid;
    grid-template-columns: 170px 1fr;
    margin-top:10px;
    font-size: 17px;
    font-family: 'Roboto',sans-serif;
    color: #384a60;

    div{
      display:flex;
      align-items: center;
      font-size: 17px;
      font-family: 'Roboto',sans-serif;
      color: #384a60;

      span{
        font-size: 17px;
        font-family: 'Roboto',sans-serif;
        color: #384a60;
      }
    }

    strong{
      margin-right:10px;
      color: #3e4a5e;;
    }

    label{
      color: #384a60;
      font-size: 17px;
      font-family: 'Roboto',sans-serif;
      margin-bottom:0px;      
    }

  }
}

.clean{
  display: block;
  height: 60px;
  position: relative;
}

.lastExpertContactInfo{
  display: flex;
  flex-direction: column;
  line-height: 21px;
  margin-top:10px;
}

.contactButtons{
  display:inline-flex;
  justify-self: center;
  align-self: center;
  margin-bottom:10px;

  &:hover{
    background:#FF004B;
    cursor:pointer;
  }

}