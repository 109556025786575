.educationsSectionWrapper{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin:0px;
  padding:0px;
  // margin-top:80px;
  padding-bottom: 140px; //100
  max-width: 1600px;

  .educationImage{
    background:url('/assets/images/egitimler-bg.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    display:block;
    width: 100%;
    height: 400px;
  }  

  .webSectionTitle{
    font-family: 'Baskerville BT',serif;
    font-weight: normal;
    font-size:52px;
    color:#384a5f;
    margin-bottom:0px;
  }


  .educationRow{
    display:flex;
    min-height:384px;
    width: 100%;
    position:relative;
    justify-content: center;
    max-width: 1366px;
    background:#c3c2c7;  

    &:nth-of-type(2){
      background:#c3c2c7;  
      max-width: 1366px;
    }

  }
  
  .educationFirstRow{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .sessizOlmazLogo{
      max-height: 150px;
      max-width: 396px;
      margin-top:60px;
      margin-bottom:40px;
    }

  }

  .educationSecondRow{
    min-height: 510px;
    padding-top:30px;

    .educationGridWrapper{
      max-width: 1366px;
      display:grid;
      grid-template-columns:1fr 1fr;
      grid-template-rows: 1fr;    
      padding:70px;     
      grid-gap: 50px;

      .education{
        background:#8b8b8d;
        height:auto;
        padding-bottom: 30px;

      }

    }

  }

  .educationGridWrapper{
    position:relative;
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows: 1fr;   
    width: 100%;
    height: 100%; 
    max-width: 1336px;   
  }

  .educationIntro{
    position:relative;
    display:flex;
    width: 100%;
    align-items: center;
    height: 300px;
    flex-direction: column;
    justify-content: center;

    p{
      font-weight: normal;
      color: #292828;
      font-size: 1.3rem;
      line-height: 1.7rem;
      text-align: center;
      font-family: "Baskerville BT", serif;
      max-width: 1000px;
      
    }

  }


  .education{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 300px;
    transition: all 1s ease;
    padding-top:45px;
    padding-left:45px;
    padding-bottom:30px; //45px default
    padding-right:45px;
    align-items: center;

    &.educationleft{
      height:300px !important;

      &.less{
        height:auto !important;
      }
    }

    &.educationright{
      height:300px !important;

      &.less{
        height:auto !important;
      }

    }


    .btnEduMore{
      position:absolute;
      bottom:-47px;
      margin-left:auto;
      margin-right: auto;
      width: 200px;
      height:40px;
      background:#fff;
      color:#0014a8;
      border:3px solid #0014a8;
      font-family: "Baskerville BT", serif;
      text-decoration: underline;
      font-size:1.3rem;
      font-weight: bold;
      padding-left:30px;
      padding-right:30px;
      box-sizing: content-box;
      text-underline-offset: 5px;
      cursor:pointer;
    }

    .educationTitle{
      color:#fff;
      font-weight: bold;
      font-size:1.7rem;
      min-height: 60px;
      font-family: "Baskerville BT", serif;
      line-height: 1.9rem;
      min-height:100px;
    }

    .educationContent{
      font-weight: normal;
      color: #fff;
      font-size: 1.2rem;
      line-height: 1.7rem;
      font-family: "Baskerville BT", serif;
      text-overflow: ellipsis;

      &.hidden{
        display:none;
      }      


     

      .educationDots{
        margin-top:15px;
        margin-left:18px;
        color:#7a6a5f;

        li{
          font-weight: normal;
          color: #c2b7b1;
          font-size: 1.1rem;
          line-height: 1.7rem;
          font-family: "Baskerville BT", serif;
        }
      }

    }

    .educationDate{
      color:#7a6a5f;
      font-weight: bold;
      font-size:0.9rem; 
      margin-top:10px;

    }
    .educationLocation{
      color:#7a6a5f;
      font-weight: bold;
      font-size:0.9rem; 
      margin-bottom:20px;            
    }



  }
}
.triangle-up {
  position: absolute;
  top:-80px;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 80px solid #8b8b8d;
}