.filterSectionWrapperID{
  display: flex;
  width: 100%;
  height: 100%;
}
.filterSectionWrapper{
  display: flex;
  width: 100%;
  min-height:400px;
  height: 565px;
  justify-content: center;
  margin:0px;
  padding:0px;
  padding-bottom:100px;
  background:#c2b7b1;
  align-items: center;
  // margin-bottom: 120px;

  .filterGridWrapper{
    position: relative;
    display:flex;
    max-width: 1336px;
    width:100%;
  }

  .filterWrapper{
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows: 80px 80px 80px;    
    grid-template-areas:
    "filterBox1 filterBox1 filterBox1 filterBox1"
    "filterBox3 filterBox3 filterBox3 filterBox3"
    "filterBox4 filterBox4 filterBox4 filterBox4";
    grid-column-gap:30px;
    grid-row-gap:0px;
    width: 100%;
    padding-top:0px;
    height: 400px;
  }
  .filterBox1 {
     grid-area: filterBox1;
     display:flex; 
     position:relative;
     justify-content: center;

  }
  .filterBox2 {
     grid-area: filterBox2;
     display:flex; 
     position:relative;
     justify-content: center;     
     max-width: 592px;
  }
  .filterBox3 { 
    grid-area: filterBox3; 
    position:relative;
    display:flex;
    justify-content: center;
    
    .editfilter{
      border:3px solid #fff;
      width:100%;
      max-width: 465px;
      height: 55px;
      background:#586a80;
      color:#fff;
      padding-left:15px;
      padding-right:15px;
      font-family: 'Baskerville BT',serif;
      font-weight: normal;
      font-size:1.1rem;

      &:focus{
        border:3px solid #000;
        border-radius: 0px;
        outline-width: 3px;
        // background:#384a60;
        outline: none;
      }
      

      &::placeholder{
        color:#fff !important;
      }
    }

    .inputWrapper.web{
      width:100%;
      max-width: 515px;
      display:block;
      background-repeat: no-repeat;  
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right:0px;

      


      .inputSubWrapper{
        position:relative;
        border:none;
        display:flex;
        width: 100%;
        height: 100%;
        background: none;
        align-items: center;
        justify-content: center;
        outline:none;    
        font-family: 'Baskerville BT',serif;
      }
      
    }

    

    .btnFilterSearch{
      margin-left:0px;
    }



    div[class*="ContentComponent"]{
      padding-left:5px;
      padding-right: 5px;

      input{
        position: absolute;
        top:0;
        bottom:0;
        margin:auto;
      }
    }
        

  
  }
  .filterBox4 { 
    grid-area: filterBox4;
    margin-top:50px;

    .inputWrapper.web.buttonwrapper{
      width: auto;

      button{
        border-radius: 0px;
        width: 100% !important;
        max-width: 300px;
        font-family: 'Baskerville BT',serif;
        color:#000;
        font-weight: normal;
        font-size: 1.4rem;
        padding:0px;
        height: 60px;

        background: #fff;

        &:hover{
          background: #000;
          color:#fff;
        }
      }

    }

  }
  .filterBox5 { grid-area: filterBox5; }
  .filterBox6 { 
    grid-area: filterBox6;
  }

  .womanExpertJoinButtonWrapper{
    width:100%;
    max-width: 515px;
    display:block;
    background-repeat: no-repeat;  
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right:10px;
    

    button{
      position:relative;
      border:none;
      display:flex;
      width: 100%;
      height: 100%;
      background: none;
      align-items: center;
      justify-content: center;
      outline:none;    
      font-family: 'Baskerville BT',serif;

      &:hover{
        cursor:pointer;
      }

      h3{
        color:#fff; 
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        line-height: 29px;
        margin: 0px;
        margin-top: 0px;
        font-family: 'Baskerville BT',serif;
        font-weight: 400;
        text-decoration: underline;
        text-underline-offset: 15px;
        text-decoration-thickness: 3px;     
              
       }    
    }
  }
  .womanExpertSearchButtonWrapper{
    width:100%;
    max-width: 515px;    
    display:block;
    align-items: center;
    position: relative;
    margin-left:10px;
    
    
    button{
      position:relative;
      border:none;
      display:flex;
      width: 100%;
      height: 100%;
      background: none;
      align-items: center;
      justify-content: flex-end;    
      outline: none;

      h3{
        color:#fff; 
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        line-height: 29px;
        margin: 0px;
        margin-right: 15px;
        font-family: 'Baskerville BT',serif;
        font-weight: 400;
        text-decoration: underline;
        text-underline-offset: 15px;
        text-decoration-thickness: 3px;     
       }
    }
  }



  .btnFilterSearch{
    margin-top:13px;
    margin-left:15px;
    width: 200px !important;
    height: 55px;
    cursor:pointer;
    transition: background-color 0.3s;

    &:hover{
      background-color:#FF004B;
    }
  }
}




.forDropdown{
  & > div:first-child{
    width: 100%;
    display:flex;
    justify-content: center;
    border:none;
  }


  .react-dropdown-select-type-single{
    span:first-child{
      color:#fff !important;
      margin-top:4px
    }
    
  }  

  .react-dropdown-select{
    border:3px solid #fff !important;
    max-width: 465px;
    width:100%;
    height: 55px;
    background:#586a80;
    color:#fff;
    padding-left:15px;
    padding-right:15px;
    font-family: 'Baskerville BT',serif !important;
    font-weight: normal;
    font-size:15px;
    outline-color: #000 !important;
    margin-top:0px;
    padding-left:10px;

    &:focus{
      background:#384a60;
    }
    &:hover{
      border:3px solid #000 !important;
    }

    .react-dropdown-select-input{
      position:relative;
      display:flex;
      align-items: center;
      // top:12px;
      font-family: 'Baskerville BT',serif !important;

      color:#fff !important;

  
      &::placeholder{
        color:#fff !important;
        font-family: 'Baskerville BT',serif !important;
  
      }
    }

    .react-dropdown-select-item-selected {
      background: #384a60 !important;
    }    

  }
  
  .react-dropdown-select-dropdown{
    border-radius: 0px;  
    font-size:15px;
    font-family: 'Baskerville BT',serif;
  }  

  .react-dropdown-select-dropdown-handle{
    svg{
      fill:#fff;
      width: 40px;
      height: 40px;
    }
  }

}