.faqSectionWrapper{
  display: flex;
  background:#efedee;
  width: 100%;
  height:100vh;
  padding-top:191px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  

  .faq__question-button{
    font-family: 'Baskerville BT',serif;
    font-size:19px;
    color:#384a60;
  }


  .webSectionTitle{
    margin-top:0px;
    margin-bottom:20px;
    font-family: 'Baskerville BT',serif;
  }

  .faqWrapper{
    display:grid;
    grid-template-columns:300px 1fr;
    grid-template-rows: 1fr;   
    grid-gap:30px;
    width: 100%;
    height: 100%; 
    max-width: 1336px;   
    padding:30px;
    font-size:1.3rem;
    
  }

  .faqContent{
    p{
      color:#384a60;
      font-size:19px;
      font-family: 'Baskerville BT',serif;
    }
    a{
      color:#384a60;
    }
    
  }




  .faqTitle{
    color:#384a60;
    font-family: 'Baskerville BT',serif;
  }

  .ulFaqList{
    list-style: none;
    font-size:1.3rem;

    li{
      color:#384a60;
      font-family: 'Baskerville BT',serif;
      font-weight: 500;

      &.selected{
        color:#384a60;
        font-weight: bold;
      }

      &:hover{
        color:#384a60;
        font-weight: bold;
        cursor:pointer;
      }
    }
  }

}
.faqListSticky{
  position:sticky;
  top:30px;
}