.medyaDeneyimiRadioGroup{
  margin-top:20px;
  display:flex;

}

.linkInfo{
  font-size:12px;
  font-style: italic;
  color:#777;
  padding:5px;
  font-family: 'Roboto',sans-serif;
}

.baglantiWrapper{
  display:flex;
  align-items: center;
}

.buttonBaglantiEkle{
  margin-left:5px;
  background:#ff004c;
  color:#fff;
  padding-left:10px;
  padding-right: 10px;
  width:100px;
  height: 40px;
  font-weight: normal;
  font-size:15px;
  border-radius: 5px;
  border:none !important;
}

.gorus-konular{
  min-width: 350px;
}
.componentWrapper{
  margin-top:0px;
  
  .step4-layer1{
    height: 230px;
  }
}

.step4GorusKonular{
  margin-top:15px;
  min-height: 250px !important;
}

.ulYayinReferanslar{
  list-style: none;
  position:relative;
  width: 100%;
  height: 100% !important;
}

.ulYayinReferanslar li{
  display:flex;
  line-height: 15px;
  margin-top:5px;
  margin-bottom:5px;
  align-items: center;
  flex-direction: row;
  flex-wrap:nowrap;
}

.ulYayinReferanslar li span{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
  font-family: 'Roboto',sans-serif;
  white-space: nowrap;
  font-size: 16px;
}

.btn-remove-list{
  position:relative;
  display:block;
  width:12px;
  height:12px;
  background:url(../../assets/images/remove-list.png);
  background-size: cover ;
  border:none;
  margin-right:5px;
  outline: none;

  &:hover{
    cursor:pointer;
  }
}
