.footerWebWrapper{
  position:fixed;
  bottom:0px;
  display:flex;
  height:79px;
  border-bottom:3px solid #FF004B;
  background:#3d475c;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 3px 11px 0 rgba(0,0,0,1);


  .sosoYazi{
    
    height: 21px;
  }



  .bottomSocial{
    margin-top:10px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    // width: 60vw;
    width: auto;
    min-width: 200px;
    margin-right: 60px;
    justify-content: end;

    li{
      display:flex;
      align-items: center;
    }

    .fab,.fas,.far{
      font-size:1.6rem;
      color:#fff;

      &:hover{
        color:#FF004B;
        cursor:pointer;
      }
    }

    a{
      margin-top:8px;
    }

  }  


}