.expertProfile{


  .socialMediaAccounts{
    .btn-remove-list {
      background: none !important;
    }

    .btn-remove-list-icon{
      max-height: 12px;
      display:block;
    }

  }

  .buttonUpload{
    box-shadow: unset !important;
  }

  .btnFilterSearch.resetHome{
    max-width: 220px;
    text-align: center;
  
    &:hover{
      background-color:#FF004B;
      color:#fff;
    }
  }

  .btnFilterSearch.save{
    &:hover{
      background-color:#FF004B;
      color:#fff;
    }    
  }


  .socialSelect {
    width: 150px;
  }

  .ant-upload-list-picture-card{
    height: 250px;
  }
  .ant-upload.ant-upload-select-picture-card{
    position: absolute;
  }

  .ant-upload-list-item-list-type-picture-card{
    max-width: 152px !important;
    max-height: 152px !important;
  }

  .ant-upload-list-picture-card-container{
    width: 187px !important;
    height: 230px !important;
    z-index: 99;
    display: flex;
    background: #e8e0db;
    justify-content: center;
  }


  .ulexpertList{
    justify-self: center;
    align-self: center;
  }

  .edits{
    margin-top:0px;
    margin-bottom:0px;
  }
  .memos{
    margin-top:0px;
    margin-bottom:0px;
    max-width: 400px;
  }

  .contentInfoWrapper{
    max-width: 400px !important;
  }

  .profileIntroUl li.profileItem{
    display: flex;
    flex-direction: column;
    margin-bottom:20px;   
    
    .react-dropdown-select {
      margin-top: 0px;
      border-radius: 5px;
      border: 1px solid #ff004c;
      max-width: 400px;
      background:#fff;
    }

    .checkContainer{
      margin-top:10px;
    }
  
    &.yasadigiulke{
      .react-dropdown-select-input {
        // display:none;
      }

      .react-dropdown-select{
        // height:40px;
        padding-left:15px;
      }
    } 

    .medyaDeneyimiRadioGroup{
      margin-top:0px;
      margin-bottom:0px;
      justify-content: flex-start;
    }   
    
    .linkInfo{
      bottom:7px;
      left:-5px;
    }

    .contentInfoWrapper{
      min-height: 40px !important;
    }


    .iletisimBilgileriCheckGroupPres {
      width: 100% !important;
      display: grid;
      grid-template-columns: 1fr 100px 100px;
      grid-template-rows: 40px 40px 40px;
      grid-template-areas:
          "formBox0 formBox1 formBox2"
          "formBox4 formBox5 formBox6"
          "formBox8 formBox9 formBox10";
      grid-gap: 5px;
      width: 100%;
      height: 100%;
      max-width: 600px;
    }

    .sectionTitle{
      font-size: 18px;
      text-align: left;
    }

    .infoAltyapi{
      max-width: 600px;
    }

    .checkContainer{
      margin-top:0px;
    }

    .iletisimBilgileriCheckGroup{
      width: auto;
    }

    .groupContacts2{
      justify-content: flex-start;
    }

    .groupContacts{
      .edits{
        max-width: 272px !important;
      }
    }

  }
  
  .baglantiWrapper{
    max-width: 400px;
    margin-top:0px;
    margin-bottom:10px;
  }

  .ulYayinReferanslar {
    list-style: none;
    position: relative;
    width: 100%;
    height: 100% !important;
    overflow: hidden;
    overflow-y: auto;
    height: 200px;
    margin: 0px;
    margin-left:0px !important;
  }


  .gorus-konular{
    padding-left:10px;
    padding-right: 10px;
  }


  .ant-upload-picture-card-wrapper {
    
  }

  .btnFilterSearch{
    &:hover{
      cursor:pointer;
    }
  }

 
  .margin-bottom-10{
    margin-bottom:10px;
  }
  
}