.bottomLayerWrapper{
  display:flex;
  position: relative;
  width:100%;
  height:80px;
  background:rgba(36,36,36,0.87);
  justify-content: center;
  align-items: center;
  margin-bottom:0px;


  p{
    color:#8f8888;
    font-weight: normal;
    font-family: 'Baskerville BT',serif;
    margin:0px;
  }
  
}