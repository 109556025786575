@media (-webkit-device-pixel-ratio: 1.25) {
  :root {
    zoom: 0.8;
  }
}

@media (-webkit-device-pixel-ratio: 1.5) {
  :root {
    zoom: 0.65;
  }
}

@media (-webkit-device-pixel-ratio: 1.75) {
  :root {
    zoom: 0.6;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
  color:#595959;
}
fast-design-system-provider {
  display: block;
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

.setfadein{
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */    
}

.nomargin{
  margin:0px !important;
}

a:hover {
  color: #ff964c;
}

main{
  /* position:relative; */
  margin-left:auto;
  margin-right: auto;
  max-width: 1920px;
}

.nav-menu{
  display:none;
}

.menu-icon{
  display:none;
}

main.signup{
  height:100vh;
  display:grid;
  grid-template-rows:147px 1fr;
  padding-top:185px;  
  background:#efeded;
  overflow-y: scroll;

  /* padding-top:10px; */
}

main.medyakayit{
  height:100vh;
  display:grid;
  grid-template-rows:85px 1fr !important;
  padding-top:185px;  
  background:#efeded;
  overflow-y: scroll;

  /* padding-top:10px; */
}

main.signup.mainBaslangic{
  height:100vh;
  display:grid;
  grid-template-rows:65px 1fr;
  padding-top:185px;  
  background:#efeded;
  overflow-y: scroll;

  /* padding-top:10px; */
}

main.home{
  /* height:100vh; */
  display:grid;
   
  /* grid-template-rows:97px 1fr 100px; */
  /* padding-top:10px; */
}




.mainSection{
  display:flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */    
}
.sectionWrapper{
  max-width: 1074px;
  width: 100%;
  height:100%;
  position:relative;
  padding-top:0px;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.girisYazi{
  font-size:16px;
  font-weight: 400;
  color:#404040;
  line-height: 30px;
  margin-top:20px;
  margin-bottom:30px;
  font-family: 'Roboto',sans-serif;
  display:none;
}

.sectionTitle{
  color:#3e495f;
  text-align: center;
  font-size: 19px;
  font-weight: 500;
  font-weight: bold;
  
}

.imageFigure{
  border-radius: 50%;
  background:#f1f1f1;
  width:152px;
  height:152px;
  display:block;
  border:3px solid #586a80;
  margin-top:20px;
  margin-bottom:20px;
}
.edits{
  height:40px;
  width:100%;
  max-width: 400px;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  border-radius: 5px;
  border:1px solid #586a80;
  margin-top:20px;
  font-size:17px;
  margin-bottom:20px;
  font-family: 'Roboto',sans-serif;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  
  outline: none;  

}

.editsfilter{
  height:40px;
  width:100%;
  max-width: 308px;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  border-radius: 5px;
  border:1px solid #9b897c;
  margin-top:20px;
  font-size:16px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  margin-bottom:20px;
  outline-color:#586a80;
  
  
}

.editsfilter::placeholder{
  font-weight: 300;
}

.editsfilter.withicon{
  padding-left:50px;
}

.editfiltericon{
  position:absolute;
  left: 14px;
  top: 28px;
}

.inputWrapper.web{
  position:relative;
  display:flex;
}
.inputWrapper.web.buttonwrapper{
  display:flex;
  justify-content: center;
  width:155px;
}

.memos{
  height:300px;
  width:100%;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  border-radius: 5px;
  border:1px solid #586a80;
  margin-top:20px;
  font-size:17px;
  margin-bottom:20px;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);
  outline:none;
}

.mainSectionWrapper{
  display:block;
  width: 100%;
}

.ant-btn-primary span{
  color:#fff;
}

.ant-btn{
  border-radius: 5px !important;
}

.ant-btn-primary{
  color: #ffffff !important;
  background: #586a80;
  border-color: #586a80;
}
.ant-btn-primary:hover,.ant-btn:hover{
    background: #384a60;
    border-color: #384a60;
    color:#fff;
}
  
.ant-btn span:hover{
  color:#fff;
}

/* Uploading... */
.ant-upload-list-item-info .ant-upload-span div[class="ant-upload-list-item-thumbnail"] {
  visibility: hidden;
}
/* you need to customise top and left css attribute */
.ant-upload-list-item-info .ant-upload-span div[class="ant-upload-list-item-thumbnail"]:after {
  content: "Yükleniyor..." !important;
  display: block;
  visibility: visible;
  display: block;
  position: absolute;
  padding: 1px;
  top: 20px;
  text-align: center;
  color:#384a60;
  width: 100%;
}

.btnWeb{
  display:block;
  /* height:55px; */
  background-color:#000;
  color:#fff;
  border:none;
  outline:none;
  font-size:19px;
  border-radius: 0px;
  padding:27px;
  padding-top:13.5px;
  padding-bottom:13.5px;  
  font-weight: bold;
  transition: background-color 0.3s;
}

.btnWeb:hover{
  background-color:#384a60;
}

.webSectionTitle{
  font-family: 'Montserrat', sans-serif;
  text-shadow: 0 0 #3e4a5e;
  margin-top:30px;
}
.btn--outline{
  background-color:transparent;
  color:#245d38;
  padding:8px 20px;
  border:1px solid #ededed;
  transition: all 0.3s ease-out;
  border-radius: 3px;
}