.contactSectionWrapper{
  position:relative;
  display:flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin:0px;
  padding:0px;
  height: 100%;
  padding-bottom:0px;
  background:rgba(36,36,36,0.87);
  padding-top:50px;
  margin-top:80px;


  .contactEmailWrapper{
    font-size: 1.1rem;
    line-height: 1.7rem;
    font-family: 'Baskerville BT',serif;
    font-weight: normal;
    text-align: center;
    margin-top:10px !important;
  }

  p{
    font-size: 1.1rem;
    line-height: 1.7rem;
    font-family: 'Baskerville BT',serif;
    font-weight: normal;
    text-align: center;
    color:#fff;
    

    &:first-of-type{
      margin-top:20px !important;
    }
  }

  h5{
    text-align: center;
    margin-top:20px;
    font-size: 1.1rem;
    font-weight: bold;
    color:#fff;
    font-family: 'Baskerville BT',serif;
  }

  .webEmailAddress{
    color:#fff;
    font-size:1.1rem;
    font-family: 'Baskerville BT',serif;
  }


  .followUsContent{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    margin-top:40px !important;
  }

  .followUsIcons{
    display:flex;
    justify-content: center;
    color:#fff;
    
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size:24px;
      color:#fff;
      text-decoration: none;
      margin-right: 15px;
      border-radius:50%;
      border:2px solid #fff;
      height:40px;
      width:40px;

      i{
        font-size:20px;
        color:#fff;
      }

    }
  }
}

.contactSectionWrapper > .webSectionTitle{
  font-family: 'Baskerville BT',serif;
  font-weight: normal;
  font-size:52px;
  color:#FFF !important;
  margin-bottom:0px;
}

.sssLinkTitle{
  margin:0px !important;
  margin-top:20px !important;
}
.btnShowCv{
  h5{
    color:#FF004B;
    transition: color 0.3s;

    &:hover{
      color:#FF964B;
    }
  }
}

.soso-bottom-logo{
  max-height: 180px;
  margin-top:30px;
}

.bottomInfoLayer{
  display: flex;
  flex-direction: column;
  width: 100% !important;
  padding:40px;
  padding-bottom:0px;

  p{
    text-align: left;
    width: 100% !important;
    padding:0px !important;
    margin:0px !important;
    font-weight: bold;
    font-size: 1rem !important;

    &.bottomspace{
      margin-bottom:10px !important;
    }

    span{
      color:#fff;
    }

  }
}