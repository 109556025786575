.articlesSectionWrapper{
  display: flex;
  width: 100%;
  min-height:900px;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin:0px;
  padding:0px;
  // margin-bottom:100px;
  padding-bottom:70px;

  .articleStartP{
    font-weight: normal;
    color: #c2b7b1;
    font-size: 1.1rem;
    padding: 30px;
    padding-top:0px;
    line-height: 1.7rem;
    font-family: 'Baskerville BT',serif;
    max-width: 1336px;
    text-align: center; 
  }

  .articlesBGWrapper{
    display:flex;
    width: 100%;
    height: 100%;
    // max-width: 1336px;
    background:url('/assets/images/sessizolmaz-writer.jpg');
    background-repeat: no-repeat;
    // background-size: 50%; // 2 li duzende
    background-position: 20px 100px;
    background-size: 40%;
    // background-position: 85px center;    
    flex-direction: column;
    align-items: center;
    
    min-height: 500px;
    padding-top:0px;
    margin-top:50px;


    .sessizOlmazLogo{
      max-height: 150px;
    }

    .articlesWrapper{
      display:grid;
      grid-template-columns:1fr 1fr 1fr;
      grid-template-rows: 1fr;   
      grid-template-areas:
      "articleBox1 articleBox2 articleBox3";
      grid-gap:20px;
      width: 100%;
      height: 100%;
      margin-top:200px;
      background:#efedee;
      padding:40px;

    }

    .articleBox{
      display: flex;
      flex-direction: column;
      padding-top:0px; //55
      padding-left:20px; //40
      padding-bottom:0px; //36
      padding-right:20px; // 40
      border-right:3px solid #fff;
      margin-top:50px;
      margin-bottom:50px;

      &:last-of-type{
        border-right: none;
      }

      &:hover{
        // cursor: pointer;
        // box-shadow: 0 6px 25px 0 rgba(0,0,0,0.1);
      }


      .articleTitle{
        color:#191919;
        text-shadow: 0 0 #191919;
        font-weight: normal;
        font-size:1.6rem;
        text-transform: uppercase;
        font-family: 'Baskerville BT',serif;
        min-height: 200px;
        margin-bottom:10px;

      }

      .articleAbstract{
        color:#000000;
        font-weight: normal;
        font-size:1.1rem;
        font-family: 'Baskerville BT',serif;
        line-height: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 140px;
        margin-bottom:50px;
      }

      .articleWriter{
        font-weight: normal;
        font-size:1.4rem;        
        font-family: 'Baskerville BT',serif;
        color:#838383;
        margin-bottom:20px;
      }

      .article-link{
        color:#ff6800;
        font-family: 'Baskerville BT',serif;
        text-decoration:underline;
        text-underline-offset: 4px;
        font-size:1.2rem;
      }

    }

    .articleBox1 {
      grid-area: articleBox1;
    }    
   
    .articleBox2 {
      grid-area: articleBox2;
      justify-self: right;
    }        

    .articleBox3 {
      grid-area: articleBox3;
    }            
   
   

  }

  .articlesMoreWrapper{
    display:grid;
    width: 100%;
    height: 100%;
    max-width: 1336px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-gap: 20px;
    max-height: 320px;
    margin-bottom: 40px;
    margin-top: 75px;
    
    .articleMoreBox{
      border-radius: 0px 45px 0px 45px;
      background:#e8e0db;
      
      background-position: left top, right bottom;
      background-size: 45px, 45px;
      background-repeat: no-repeat, no-repeat;     
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-top:55px; //55
      padding-left:40px; //40
      padding-bottom:36px; //36
      padding-right:40px; // 40
      max-width: 432px;

      &:hover{
        cursor: pointer;
        box-shadow: 0 6px 25px 0 rgba(0,0,0,0.1);
      }


      .articleTitle{
        color: #FF004B;
        font-weight: bold;
        font-size: 1.2rem;
        text-transform: uppercase;
        min-height: 100px;
      }

      .articleAbstract{
        color: #7a6a5f;
        font-weight: normal;
        font-size: 1rem;
        font-family: 'Roboto',sans-serif;
        line-height: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 140px; 
      }

      .articleWriter{
        color:#7a6a5f;
        font-weight: bold;
        font-size:0.9rem;        
      }

    }    
  }
}
.btnShowMore{
  height: 55px;
  cursor:pointer;
  margin-top:35px;
  margin-bottom:50px;

  &:hover{
    background:#FF004B;
  }
}
.btnArticlesShowMore{
  margin-top: 90px;
  margin-bottom: 0px;
  margin-left:auto;
  margin-right: auto;
}
.btnArticlesShowLess{
  margin-top: 0px;
  margin-left:auto;
  margin-right: auto;

}
.webSectionTitle{
  color:#3e4a5e;
  font-size: 32px;
  font-weight: bold;
  margin-bottom:50px;
}
.btnhide{
  display:none;
}
.webLink{
  color:#7a6a5f;
  font-weight: bold;

  &:hover{
    color:#FF004B;
    font-weight: bold;
  }
}