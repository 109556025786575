.cvsSectionWrapper{
  display: flex;
  background:#efedee;
  width: 100%;
  height:100vh;
  padding-top:191px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  


  .webSectionTitle{
    margin-top:0px;
    margin-bottom:20px;
  }

  .cvsWrapper{
    display:grid;
    grid-template-columns:300px 1fr;
    grid-template-rows: 1fr;   
    grid-gap:30px;
    width: 100%;
    height: 100%; 
    max-width: 1336px;   
    padding:30px;
    font-size:1.3rem;
    
  }

  .cvContent{
    p{
      color:#384a60;
      font-size:19px;
      font-family: 'Baskerville BT',serif;
  
    }
    
  }


  .cvTitle{
    color:#384a60;
    font-size:24px;
  }

  .ulCvList{
    list-style: none;
    font-size:1.3rem;

    li{
      margin-bottom: 10px;
      color:#384a60;
      font-weight: 500;
      font-size: 19px;
      font-family: 'Baskerville BT',serif;
      line-height: 19px;      

      &.selected{
        color:#384a60;
        font-weight: bold;
        font-family: 'Baskerville BT',serif;
        line-height: 19px;    
        font-size: 19px;    
      }

      &:hover{
        color:#384a60;
        cursor:pointer;
        font-weight: bold;
        font-family: 'Baskerville BT',serif;
        line-height: 19px;    
        font-size: 19px;        
      }
    }
  }

}
.cvListSticky{
  position:sticky;
  top:30px;
}