footer{
  height: 65px;
  width: 100%; 
  display: flex;
  box-sizing: border-box;
  position:fixed;
  bottom:10px;
  padding-left:3%;
  padding-right:3%;     
  justify-content: space-between;
}


.nextButton{
  height:65px;
  fill:#384a60;
}
.nextButtonDone{
  height:45px;
  fill:#384a60;
}

.nextButtonLinkWrapper{
  display:flex;
  align-items: center;
  text-decoration: none;
}

.nextButtonLinkWrapper:hover{
  .nextButtonText{
    color:#ff964c;
  }

  .nextButton{
    fill:#ff964c;
  }

  .nextButtonDone{
    fill:#ff964c; 
  }

}

.nextButtonText{
  color:#384a60;
  text-decoration: none;
  margin-right:-15px;
  font-weight: 500;
  margin-top:5px;
  font-size:21px;
}
.nextButtonTextDone{
  margin-right:10px;
}






.backButton{
  height:65px;
  fill:#384a60;
}
.backButtonDone{
  height:45px;
  fill:#384a60;
}

.backButtonLinkWrapper{
  display:flex;
  align-items: center;
  text-decoration: none;
}

.backButtonLinkWrapper:hover{
  .backButtonText{
    color:#ff964c;
  }

  .backButton{
    fill:#ff964c;
  }

  .backButtonDone{
    fill:#ff964c; 
  }

}

.backButtonText{
  color:#384a60;
  text-decoration: none;
  margin-left:-15px;
  font-weight: 500;
  margin-top:5px;
  font-size:21px;
}
.backButtonTextDone{
  margin-left:10px;
}


