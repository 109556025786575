.gridWrapperStep5{
  width:100%;
  display:grid;
  grid-template-columns: 1fr 1fr;

  .sectionTitle{
    text-align: center;
    font-size: 19px;
    font-weight: 500;
    font-weight: bold;
  }

  .medyaDeneyimiRadioGroup {
    margin-top:0px;
  }

  .baglantiWrapper > .edits{
    margin:0px !important;
    width:calc(100% - 190px);
  }
  

}
.baglantiWrapper{
  margin-top:20px;
  width: 100%;
  max-width: 560px;
}

.baglantiWrapper > .edits{
  margin:0px !important;
}

.contactWrapper{
  display:flex;
  width:100%;
  .edits{
    font-size:14px;
    margin-top:5px !important;
    margin-bottom:5px !important;
  }
}

.contactCheck{
  margin-right: 10px;
  width:160px;
}

.medyaDeneyimiRadioGroup{
  margin-bottom: 30px;
}

.contactWrapperInfo{
  max-width: 369px;
  width:100%;
  font-size:15px;
  margin-bottom:10px;
}
.infoAltyapi{
  font-size:14px;
  color:#666;
  line-height: 21px;
  margin-bottom: 0px;
  font-family: 'Roboto',sans-serif;
}
.altyapiSub{
  font-size:14px;
  color:#384a60;
  display:block;
  clear:both;
  font-weight: normal;
  margin-top:5px;
  margin-bottom:10px;
  font-family: 'Roboto',sans-serif;
}

.groupContacts{
  display:flex;
  align-items: center;
}
.iletisimBilgileriCheckGroup{
  width:580px;
  display:flex;

  .checkContainer{
    width:auto !important;
    margin-right:10px;
  }
}
.groupContacts2{
  display: flex;
  justify-content: flex-end;
  width: 100%;


  .contactCheck{
    width:130px;
  }

  .edits{
    max-width: 500px;
  }

}

.step5SosyalMedya{
  margin-top:15px;
  padding-top:15px;
  overflow:hidden;
  overflow-y:auto !important;
}



.socialMedyaInfo{
  margin-left:5px;
  margin-top:1px;
  color:#666;
  max-width: calc(100% - 40px);
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.onayTick{
  font-size:15px;
  margin-top:50px;
  position: relative;
  display: flex;
  font-weight: 500;
  justify-self: end;
  
}

.contactWrapper .checkContainer{
  width: auto;
  min-width: 100px;
  margin-bottom:0px;
  margin-right:10px;
}
.buttonBaglantiEkle{
  margin-left:5px;
  background:#384a60;
  color:#fff;
  padding-left:10px;
  padding-right: 10px;
  width:100px;
  height: 40px;
  font-weight: normal;
  font-size:15px;
  border-radius: 5px;
  outline:none;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  

  &:hover{
    cursor: pointer;
    background:#586a80;
  }
}

.iletisimBilgileriCheckGroupPre{
  display:flex;
}
.iletisimBilgileriCheckGroupPres{
  width:100% !important;
  display:grid;
  grid-template-columns:61px 1fr 100px 100px;
  grid-template-rows: 40px 60px 60px;   
  grid-template-areas:
  ". formBox0 formBox1 formBox2"
  "formBox3 formBox4 formBox5 formBox6"
  "formBox7 formBox8 formBox9 formBox10";
  grid-gap:5px;
  width: 100%;
  height: 100%;


  .formBox0{
    grid-area: formBox0;
 
  }

  .formBox1 {
    grid-area: formBox1;
    display:flex;
    align-items: center;
    text-align: center;

    h5{
      font-size:14px;
      font-weight: bold;    
      color:#3e495f;
    }

  }    
 
  .formBox2 {
    grid-area: formBox2;
    display:flex;
    align-items: center;    
    text-align: center;


    h5{
      font-size:14px;
      font-weight: bold; 
      color:#3e495f;   
    }

  }        

  .formBox3 {
    grid-area: formBox3;
    display:flex;
    align-items: center;
    justify-content: flex-end;    
  }     

  .formBox4 {
    grid-area: formBox4;
    display:flex;
    align-items: center;
    justify-content: center;        
  }     

  .formBox5 {
    grid-area: formBox5;
    display:flex;
    align-items: center;
    justify-content: center;    

    .checkContainer{
      margin-bottom:0px;
    }

  }     

  .formBox6 {
    grid-area: formBox6;
    display:flex;
    align-items: center;
    justify-content: center; 
    
    .checkContainer{
      margin-bottom:0px;
    }

    
  } 
  
  .formBox7 {
    grid-area: formBox7;
    display:flex;
    align-items: center;
    justify-content: flex-end;    
  }     

  .formBox8 {
    grid-area: formBox8;
    display:flex;
    align-items: center;
    justify-content: center;    
  }     
  .formBox9 {
    grid-area: formBox9;
    display:flex;
    align-items: center;
    justify-content: center;    

    .checkContainer{
      margin-bottom:0px;
    }


  }     
  .formBox10 {
    grid-area: formBox10;
    display:flex;
    align-items: center;
    justify-content: center;    

    .checkContainer{
      margin-bottom:0px;
    }

  }     



  





  .contactWrapper{
    width:100% !important;
  }
}



.medyaDeneyimiRadioGroupWrapper{
  width:100%;
  display: flex;
  justify-content: center;
}
.medyaDeneyimiRadioGroup{
  width:  100%;
  display: flex;
  justify-content: center;
}

.contactTercih{
  width:unset !important;
  display: flex;
  flex-direction: row;
  margin-bottom:40px;
}

.sectionBottom{
  margin-bottom: 20px;
}
.margin-top-x{
  margin-top:40px;
}

.ulSosyalHesaplar{
  list-style: none;
  position:relative;
  width: 100%;
}

.ulSosyalHesaplar li{
  display:flex;
  line-height: 15px;
  margin-top:5px;
  margin-bottom:5px;
  align-items: center;
  flex-direction: row;
  flex-wrap:nowrap;
  position:relative;
}

.ulSosyalHesaplar li .socialLine{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.socialLine{
  margin-top:5px;
  margin-bottom:5px;
  display: flex;
  align-items:center;
}

.socialSelect{
  width:85px;
}

.socialSelect div[class*="ValueContainer"] {
  height:36px;
  padding:3px;
}

.socialSelectWrapper{
  padding:10px;
  display: flex;
  justify-content: center;
}

.socialSelect{
  border-radius: 5px;
  border:1px solid #586a80;
  outline-color: #000 !important;
  margin-right:4px;

}

.react-dropdown-select{
  margin-top:10px;
  border-radius: 5px;
  border:1px solid #586a80;
  outline-color: #000 !important;
}

.react-dropdown-select-dropdown{
  border-radius: 5px;  
  font-size:15px;
}

.modalForm{
  position: relative;
  height: 85vh !important;
  display: block;
  padding: 60px;
  padding-bottom:20px;
  overflow: hidden;
  overflow-y: auto;  
}
.textCenter{
  text-align: center;
}

.fixclose{
  position: absolute;
  right: 40px;
  top: 10px;
  max-height: 35px;  
  z-index:9;
  background:none;
  border:none;
  width:35px;
  height: 35px;
  display:block;
  outline:none;
  cursor:pointer;

}

.closeButton{
  color:#384a60 !important;
  font-weight: lighter;
  height: 35px;
}
.on-bilgilendirme-formu-text u{
  &:hover{
    color:#384a60 !important;
  }
  
}

.popup-content{
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */    
}
.componentWrapper.step5-layer1{
  height: 232px !important;
}