.articlePageSectionWrapper{
  display: flex;
  background:#e8e0db;
  width: 100%;
  height:100vh;
  padding-top:191px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  


  .webSectionTitle{
    margin-top:0px;
    margin-bottom:20px;
  }

  .articlePageWrapper{
    display:grid;
    grid-template-columns:300px 1fr;
    grid-template-rows: 1fr;   
    grid-gap:30px;
    width: 100%;
    height: 100%; 
    max-width: 1336px;   
    padding:30px;
    font-size:1.3rem;
    
  }

  .articlePageContent{
    padding-left:40px;
    padding-right:40px;    
  }




  .articlePageTitle{
    color:#FF004B;
  }

  .ulArticleList{
    list-style: none;
    font-size:1.3rem;
    padding-top:10px;

    li{
      margin-bottom: 10px;
      line-height: 15px;

      &.selected{
        a{
          font-size:17px;
          color:#FF004B;
          font-family: 'Roboto',sans-serif;
          line-height: 17px;
        }
        
      }


      a{
        font-size:17px;
        color:#c2b7b1;
        font-weight: 500;
        font-family: 'Roboto',sans-serif;
        line-height: 17px;

        &.selected{
          color:#FF004B;
        }
  
        &:hover{
          color:#FF004B;
          cursor:pointer;
        }
    
      }

    }
  }

}

.articleListSticky{
  position:sticky;
  top:30px;

}