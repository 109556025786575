.aboutlayerSectionWrapper{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin:0px;
  padding:0px;
  background:#efeded;
  // margin-top:80px;
  padding-top:60px; 
  padding-bottom: 140px; //100

  .webSectionTitle{
    margin-bottom:40px;
    font-family: 'Baskerville BT',serif;
    font-weight: normal;
    font-size:48px;
    color:#384a5f;
    margin-bottom: 50px;


  }


  .aboutlayerRow{
    display:flex;
    height: 100%;
    min-height: 384px;
    width: 100%;
    position:relative;
    justify-content: center;

  }

  .aboutlayerGridWrapper{
    position:relative;
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows: 1fr;   
    width: 100%;
    height: 100%; 
    max-width: 1336px;   
  }

  .aboutlayerIntro{
    display:flex;
    width: 100%;
    align-items: center;
    height: 100%;

    p{
      font-weight: normal;
      color:#535353;
      font-size: 1.3rem;
      padding:30px;
      line-height: 1.7rem;
      font-family: 'Baskerville BT',serif;
      
    }

  }

  .aboutlayer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100px;

    padding-left:45px;
    padding-right:45px;

    .aboutlayerTitle{
      color:#FF004B;
      font-weight: bold;
      font-size:1.2rem;
    }

    .aboutlayerContent{
      font-weight: normal;
      color: #535353;
      font-size: 1.3rem;
      line-height: 1.7rem;
      text-align: center;
      font-family: 'Baskerville BT',serif;
      

      strong{
        color: #535353;
      }

      .aboutlayerDots{
        margin-top:15px;
        margin-left:18px;
        color:#535353;

        li{
          font-weight: normal;
          color: #535353;
          font-size: 1.1rem;
          line-height: 1.7rem;
          font-family: 'Baskerville BT',serif;
        }
      }

    }

    .aboutlayerDate{
      color:#535353;
      font-weight: bold;
      font-size:0.9rem; 
      margin-top:10px;

    }
    .aboutlayerLocation{
      color:#535353;
      font-weight: bold;
      font-size:0.9rem; 
      margin-bottom:20px;            
    }



  }
}