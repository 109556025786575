.bottomMenu{
  display: flex;  
  list-style: none;  
  margin:0px;
  height: 100%;

  li{
    a{
      color:#595959;
      font-size: 1rem;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      width: auto;
      height: 40px;
      padding-left:20px;
      padding-right:20px;
      letter-spacing: 3px;
      display:flex;
      align-items: center;
      justify-content: center;
      
      &:last-of-type{
        padding-right: 0px;
      }

      span{
        color:#000000;
        text-align: center;
      }
    }
  }

}

.menuSelected {
  color:#000000 !important;
  font-size: 1rem !important;
}

.menuLiSelected{
  position:relative;
  text-decoration: underline;
  // border-bottom:3px solid #FF004B;
  // border-radius: -5px;
  text-underline-offset: 15px;
}
