@media screen and (max-width:450px){
  .sliderWrapper{
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-around !important;    
  }
}
@media screen and (max-width:700px){
  /* Chat Section Wrapper*/
  .chatFormWrapper-content{
    border-radius: unset;
  }

  .modalForm{

    .chatSectionWrapper{
      display: flex;
      width: 100%;
      height: calc(100% - 400px);
      flex-direction: column;
      grid-template-columns: 250px 1fr;
      grid-template-rows: 100%;
      grid-template-areas: "contacts messages";
      grid-column-gap: 10px;
      margin-top: 10px;   
      
      .chatContacts{
        height: 150px;
      }

      .chatMessages{
        height: calc(100vh - 370px);
        margin-top:10px;
        min-height: 200px;
      }
      
    }
  

  }

  /* End of Chat section Wrapper */


  /* Home Page */
  .filterSectionWrapper .womanExpertSearchButtonWrapper {
    height: 90px;
    width:258px;
    

    button{
      h3{
        font-size:19px;
        line-height: 21px;
        width:164px;
      }
    }

  }  
  .filterSectionWrapper .womanExpertJoinButtonWrapper {
    height: 90px;
    width:258px;
    
    button{
      h3{
        font-size:19px;
        line-height: 21px;
        width:164px;
        margin-top:-20px;
      }
    }    
  }
  .filterBox1{
    flex-direction: column;
    align-items: center;
  }

}
@media screen and (max-width: 960px) {

  /*Home Page*/

  main{
    position: relative;
    display:grid;
  }

  .navsub-menu-sub{

    .nav-item-sub:not(:last-child){
      display:none;
    }
    
  }

  .sessizOlmazLogo{
    max-width: 280px;
  }

  #navsubID{
    .logoContainer{
      top:10px;
      padding:0px;
      img{
        width: auto;
        max-width: 100% !important;
        height: 50px;

      }
    }

  }

  .font-weight-bold{
    color:#384a60 !important;
  }

  .navbarhome{

    position:absolute;
    width:100%;
    height: 67px;
    z-index:4;
    padding-bottom: 0px;

    .navbarhome-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;

      .logoContainer{
        padding: 0px;
        top:0px;
    
        .navbarhome-logo{
          max-width: 100px;
        }
    
  
      }
  

    }  
    
    .nav-menu-sub-left{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-left{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }

    .nav-menu-sub-right{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-right{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }    


    .nav-menu-sub{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub{
        &:nth-of-type(2){
          display: none;
        }
        &:last-of-type{
          display: none;
        }
      }
    }

  }

  .navsubbarhome{

    position:fixed;
    width:100%;
    height: 67px;
    z-index:4;

    .navsubbarhome-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      height: 67px;
      width: 100%;

      .logoContainer{
        padding: 30px;
        padding-bottom: 15px;
        padding-top: 45px;
    
        .navsubbarhome-logo{
          width:200px;
          max-width: 200px;
        }
    
  
      }
  

    }  
    
    .nav-menu-sub-left{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-left{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }
    .nav-menu-sub-right{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-right{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }


    .nav-menu-sub{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub{
        &:nth-of-type(2){
          display: none;
        }
        &:last-of-type{
          display: none;
        }
      }
    }

  }

  .mainMenuWrapper{
    display:none;
  }




  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-top:50px;
    padding-right: 0px;
  }

  .nav-menu.active {
    background: #384a60;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y:auto;
  }  


  .nav-links-main {
    text-align: center;
    color:#fff;
    padding: 1rem;
    width: 100%;
    display: table;
    margin-right:0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }

  .nav-links-main:hover {
    background-color: #ffffff;
    color: #384a60;
    border-radius: 0;
  }  

  .sliderWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    
    height: 375px;
    background-size: cover;
    margin-top:67px;
    background-repeat: no-repeat;
    background-position: center;

    .btnWatchVideo{
      position: relative;
      max-width: 315px;
      width: auto;
      height: 50px;
      left:0px;
      padding:5px;
      display: flex;
      justify-content: space-around;
      bottom:20px;
      font-size: 14px;

      img{
        max-height: 16px;
        margin-left:10px;
      }

    }

    

    .sliderQuoteWrapper{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      bottom:20px;
    }

    .sliderQuote{
      display: flex;
      align-items: center;
      text-align: center;
      width: auto;
      font-size:17px;
      line-height: 17px;
      height:30px;
      margin-bottom: 4px;
      background:#060606;
      padding:10px;
      
      
    }
  }


  .filterSectionWrapper{

    padding-bottom:0px;
    padding-top:40px;
    align-items: flex-start;

    .filterWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: unset;
      // min-height: 400px;
      padding-top: 0px;
    }
    
    .react-dropdown-select{
      margin-top:0px;
    }

    .btnFilterSearch{
      margin-left:0px;
    }

    .filterGridWrapper{
      width:100%;
      max-width: calc(100% - 40px);

      .filterBox1{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        max-width: unset;
      }
      .filterBox2{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        margin-top:20px;
        max-width: unset;
      }
      .filterBox3{
        display:flex;
        width:100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .inputWrapper.web{
          margin:0px;
          margin-top:10px;
        }

      }
      .filterBox4{
        display:block;
        width:100%;
        height: 100%;

        .inputWrapper.web{
          
          div{
            &:first-of-type{
              width: 100%;
              max-width: 400px;
            }
          }
          
        }

      }
      .filterBox5{
        display:block;
        width:100%;
        height: 100%;

        .inputWrapper.web{

          div{
            &:first-of-type{
              width: 100%;
              max-width: 400px;
            }
          }
          
        }

      }
      .filterBox6{
        display:block;
        width:100%;
        height: 100%;

        .buttonwrapper{
          display:flex;
          justify-content: center;
        }


      }

      .womanExpertSearchButtonWrapper {
        height: 90px;
        width:100%;
        display: flex;
        justify-content: center;
        
    
        button{
          justify-content: center;
          h3{
            font-size:15px;
            line-height: 21px;
            width:100%;
            text-align: center;
          }
        }
    
      }  
      .womanExpertJoinButtonWrapper {
        display: flex;
        height: 90px;
        width:100%;
        justify-content: center;
        button{
          justify-content: center;
          h3{
            font-size:15px;
            line-height: 21px;
            width:100%;
            margin-top:-20px;
            text-align: center
          }
        }    
      }    

      .inputWrapper.web{
        padding:0px;

        .editfiltericon{
            left:80px;
            display: none;
        }

        .editsfilter{
          width:100%;
          max-width: 400px;
          margin-bottom:0px;
          font-size: 17px;
          font-weight: normal;
          outline-color: #FF004B;
        }
      }

      .editsfilter.withicon{
        background:url('/assets/images/filter-search-icon.png');
        background-position: center left 10px;
        background-repeat: no-repeat;
      }


    }

  }

  .footerWebWrapper{
    display:none;
  }


  .webSectionTitle{
    text-align: center;
    line-height: 29px;
    margin-top:10px;
    font-size: 32px !important;
  }


  .aboutlayerSectionWrapper{
    display:block;
    padding-bottom: 80px;

    .aboutlayerRow{
      height: 100%;
    }

    .aboutlayerGridWrapper{
      display:flex;
      flex-direction: column;      
    }

  }

  .articlesSectionWrapper{
    display: block;
    min-height: unset;
    padding-bottom: 80px;
    padding-left:10px;
    padding-right: 10px;

    .articlesBGWrapper{

      background-size: 102%;
      background-position: 85px 160px;
      max-height: unset;

      .articlesWrapper{
        display:flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;

        .articleBox{
          max-width: 432px;
          margin-top: 20px;
          border:0px !important;
        }
      }
    }

    .articlesMoreWrapper{
      max-height: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .articlesMoreWrapper{
        display:flex;
        flex-direction: column;
        align-items: center;

        .articleMoreBox{
          max-width: 432px;
          margin-top: 20px;
        }
      }

    }

  }


  .educationsSectionWrapper{
    display:block;
    padding-bottom:80px;


    .sessizOlmazLogo{
      max-width: 280px !important;
      margin-bottom:0px !important;
    }

    .educationFirstRow{
      height:100%;
    }

    .educationRow{

      height: 100%;

      .educationGridWrapper{
        display:flex;
        flex-direction: column;
        padding:30px !important;
        padding-top:70px !important;
        padding-bottom:70px !important;

        .educationImage{
          background-size: cover;
          background-position: bottom;
          min-height: 300px;          
        }

        .education{
          min-height: 330px !important;
          .educationContent{
            font-size:16px;
          }
        }
        .educationleft{
          height: auto !important;
        }
        .educationright{
          height: auto !important;
          margin-top:140px;
        }

      }
    }

  }


  .aboutSectionWrapper{
    

    .aboutRow {
      height: 100%;
    }

    .aboutGridWrapper{
      display: flex;
      flex-direction: column;
    }

    .aboutImage{
      height: 100%;
      min-height: 300px;
    }

  }

  .bottomLayerWrapper{
    margin-bottom: 0px;

    p{
      text-align: center;
      padding-left:10px;
      padding-right:10px;
    }
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -18px;
    right: -10px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .contentInfoWrapper.gorus-konular.country-city{
    height: 100% !important;
  }


  .cvsSectionWrapper{
    padding:20px;
    padding-top:100px;

    .cvsWrapper{
      display: flex;
      flex-direction: column;
      padding:0px;
    }
  }

  .searchSectionWrapper{
    padding:20px;
    padding-top:100px;


    .searchWrapper{
      display: flex;
      flex-direction: column;
      padding:0px;
    }    

    .searchListSticky{
      align-items: center;
      margin-top:20px;
    }
  }
  
  .expertSectionWrapper{
    padding:20px;
    padding-top:100px;


    .expertWrapper{
      display: flex;
      flex-direction: column;
      padding:0px;
    }    

    .expertListSticky{
      align-items: center;
      margin-top:20px;
    }    
  }

  .articlePageSectionWrapper{
    padding:20px;
    padding-top:100px;


    .articlePageWrapper{
      display: flex;
      flex-direction: column;
      padding: 0px;

      .articlePageContent{
        padding:0px;
      }

    }

  }  

  .faqSectionWrapper{
    padding-top:120px;

    .webSectionTitle{
      margin-bottom:0px;
    }

    .faqWrapper{
      display:flex;
      flex-direction: column;
      padding:0px;

      .faqContent{
        width: 100%;
      }
      .faq{
        width:100%;
        padding-top:0px;

        .faq__list{
          display:block;
          width: 100%;
          padding-right: 10px;
          padding-left: 10px;
        }
    

        .faq__question{
          max-width: 100%;
  

          dd,dt{
 
          }
          
        }

        .faq__question-button{
          width: 100%;

        }


        button{
          font-size: 16px;
          line-height: 21px;
        }

      }

    }  
  

  }
  
  .ozgecmisTitle{
    max-width: 280px;
  }
  .btnShowOzgecmis{
    max-width: 280px;
  }


  /* Sign Up*/
  .closeSignUp {
    color: #ff004c !important;
    font-weight: lighter;
    position: absolute;
    right: 15px;
    top: -10px;
    max-height: 25px;
  } 

  main.signup {
    height: 100%;
    display: flex;
    grid-template-rows: 97px 1fr;
    padding-top: 115px;
    background: #e8e0db;
    overflow-y: scroll;
    flex-direction: column;
    /* padding-top: 10px; */
  }  


  .nav-links {
  
    font-size:15px;
    width:unset;
  
  }

  .headerContentWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-top: 20px;   
  }

  .headerContentWrapper > h2 {
    margin-top:0px;
  }  

  .navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap:5px;
       
  }

  .topHeader{
    height: 100px;
  }

  .medyakayit{
   
    .topHeader{
      height: 40px;
    }

    .mainSection{
      margin-top:20px;
    }
    
  }


  .sectionWrapper{
    padding-top:0px;
  }


  .mainInfoWrapper{
    flex-direction: column;
    margin-top:40px;
    align-items: center;
    padding:20px;
  }

  .inputWrapper .edits{
    margin-top:0px;
  }
  .titleAdsoyad{
    margin-top:10px;
  }
  
  footer{
    position: relative;
    margin-top:10px;
    margin-bottom: 10px;
    bottom:15px;
  }

  .gridWrapper{
    display: flex;
    flex-direction: column;
  }

  .componentWrapper{
    margin-bottom:20px;
    min-width: 100% im !important;
  }



  .iletisimBilgileriCheckGroup{
    width:100%;
  }


  .groupContacts{
    flex-direction: column;
  }

  .contactWrapper .checkContainer{
    min-width: unset;
  }

  .groupContacts2{
    flex-direction: column;
  }

  .mainSection{
    padding:20px;
    margin-top:40px;
  }

  .gorus-konular{
    min-width: 100% !important;
  }
  .contentInfoWrapper{
    min-width: 100% !important;
  }

  .sectionWrapper{
    padding-top:10px !important;
  }
  .gridWrapper{
    grid-gap:0px !important;
  }

  .gridWrapperStep5{

    .medyaDeneyimiRadioGroup{
      margin-top:30px;
    }

    .baglantiWrapper{
      flex-direction: column;

      .edits{
        width: 100%;
        margin-top:10px !important;
      }
      .buttonBaglantiEkle{
        margin-top:10px;
      }
    }

    .formBox3{
      display:none;

    }

    .formBox7{
      display:none;
    }

    .groupContacts2{
      margin-top:20px !important;
    }


    .contactWrapper .edits {
      font-size: 14px;
      margin-top: 10px !important;
      margin-bottom: 5px !important;
      align-self: self-start;
    }


  }

  .infoAltyapi{
    margin-bottom:20px;
  }

  .iletisimBilgileriCheckGroupPres{
    width: 100% !important;
    display: grid;
    grid-template-columns: 1px 1fr 50px 50px;
    grid-template-rows: 40px 60px 60px;
    grid-template-areas:
        ". formBox0 formBox1 formBox2"
        "formBox3 formBox4 formBox5 formBox6"
        "formBox7 formBox8 formBox9 formBox10";
    grid-gap: 5px;
    width: 100%;
    height: 100%;
 
    div{
      justify-content: center !important;
    }

    .formBox1 h5 {
      font-size: 12px;
    }

    .formBox2 h5 {
      font-size: 12px;
    }


   
  }

  .edits{
    font-size:15px;
  }
  
  .popup-content{
    width: 90%;
  }
  .modalForm{
    padding:20px;
  }

  .mainInfoWrapperKayitFormuRow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

}
@media only screen and (min-width: 961px) and (max-width: 1199px) {

  #mainID{
    // padding-left:150px;
    // padding-right:150px;
  }  

  #navID{
    padding-left:10px;
    padding-right: 10px;

    &:not(.navbarsticky){
      .bottomMenu li a{
        font-size:12px; 
       }
       .linka{
         font-size:17px;
       }
   
       .img-katilim {
         width:23px;
         height:23px;
       }
   
       .linkToLogin{
         font-size: 17px;;
       }
   
       .img-bilgilerim{
         width: 23px;
         height: 23px;
       }
    }

    &.navbarsticky{
      .bottomMenu li a{
        font-size:12px !important; 
        padding-left:8px;
       }
       .linka{
         font-size:17px;
       }
   
       .img-katilim {
         width:23px;
         height:23px;
       }
   
       .linkToLogin{
         font-size: 17px;;
       }
   
       .img-bilgilerim{
         width: 23px;
         height: 23px;
       }

       .nav-item-sub-right{
        left:0px;
       }

    }    
  }

  .womanExpertSearchButtonWrapper{
    h3{
      font-size: 21px;
    }    
  }
  .womanExpertJoinButtonWrapper{
    h3{
      font-size: 21px;
    }
  }

  .sliderID{
    padding-left:10px;
    padding-right: 10px;
  }

  .sliderWrapper{
    height:595px;
    background-size: cover;
    background-position: bottom;

    .btnWatchVideo{
      left:60px;
    }

    .sliderQuote{
      width:320px;
      height:30px;
      font-size:32px;
    }


  }
  .filterSectionWrapperID{
    padding-left:10px;
    padding-right: 10px;
  }  
  .filterSectionWrapper{
    padding-bottom:10px;
  }

  #hakkindIDWrapper{
    padding-left:10px;
    padding-right: 10px;    
  }
  #makalelerIDWrapper{
    padding-left:10px;
    padding-right: 10px;    
  }
  #egitimlerIDWrapper{
    padding-left:10px;
    padding-right: 10px;    
  }
  /***

    Home Page and Sub Pages

  ***/
  .educationsSectionWrapper{
    .educationTitle{
      font-size:1.3rem !important;
      margin-bottom: 30px;
    }
  }


  /***

    SignUp Page

  ***/  

  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      // height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;
    bottom: 10px;
    padding-left: 3%;
    padding-right: 3%;
    justify-content: space-between;
    margin-top: 50px;
    padding-bottom: 40px;  
  }

}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {

  #mainID{
    // padding-left:150px;
    // padding-right:150px;
  }  

  #navID{
    padding-left:30px;
    padding-right: 30px;
  }
  .sliderID{
    padding-left:30px;
    padding-right: 30px;
  }

  .sliderWrapper{
    height:595px;
    background-size: cover;
    background-position: bottom;

    .btnWatchVideo{
      left:60px;
    }

    .sliderQuote{
      width:320px;
      height:30px;
      font-size:32px;
    }


  }
  .filterSectionWrapperID{
    padding-left:30px;
    padding-right: 30px;
  }  
  .filterSectionWrapper{
    padding-bottom:10px;
  }

  #hakkindIDWrapper{
    padding-left:30px;
    padding-right: 30px;    
  }
  #makalelerIDWrapper{
    padding-left:30px;
    padding-right: 30px;    
  }
  #egitimlerIDWrapper{
    padding-left:30px;
    padding-right: 30px;    
  }
   
 
 

  /* End of Header and Slider */

  /*  Footer Menu  */
  .bottomMenu{
    margin-left:0px;

    li{

      a{
        width:auto;
      }

    }

  }

  .footerWebWrapper{
    .bottomSocial{
      margin-top:18px;
      margin-right:18px;
      grid-gap:30px;

      .sosoYazi{
        display:none;
      }

    }
  }

  .educationsSectionWrapper{
    .educationTitle{
      font-size:1.4rem !important;
    }
  }

  

  /***

    SignUp Page

  ***/  

  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      // height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;
    bottom: 10px;
    padding-left: 3%;
    padding-right: 3%;
    justify-content: space-between;
    margin-top: 50px;
    padding-bottom: 40px;  
  }

}
@media only screen and (min-width: 1366px) and (max-width: 1599px){

  #mainID{
    // padding-left:150px;
    // padding-right:150px;
  }  

  #navID{
    padding-left:30px;
    padding-right: 30px;
  }
  .sliderID{
    padding-left:30px;
    padding-right: 30px;
  }

  .sliderWrapper{
    height:595px;
    background-size: cover;
    background-position: bottom;

    .btnWatchVideo{
      left:60px;
    }

    .sliderQuote{
      width:380px;
      font-size:40px;
    }


  }
  .filterSectionWrapperID{
    padding-left:30px;
    padding-right: 30px;
  }  
  .filterSectionWrapper{
    padding-bottom:10px;
  }

  #hakkindIDWrapper{
    padding-left:30px;
    padding-right: 30px;    
  }
  #makalelerIDWrapper{
    padding-left:30px;
    padding-right: 30px;    
  }
  #egitimlerIDWrapper{
    padding-left:30px;
    padding-right: 30px;    
  }
   
    /***

    Home Page and Sub Pages

  ***/
  /* Top Layer Navivation*/
  .nav-item-sub-left{
    left:20px;
  }
  .nav-item-sub-right{
    left:20px;
  }

  .nav-item-sub:nth-of-type(3){
    margin-left:20px;
  }


  /* End of Top Layer Navigation*/

  /* Header and Slider */
 



  /* End of Header and Slider */

  /*  Footer Menu  */
  .bottomMenu{
    margin-left:0px;

    li{

      a{
        width:auto;
      }

    }

  }

  .footerWebWrapper{
    .bottomSocial{
      margin-top:18px;
      margin-right:18px;
      grid-gap:30px;

      .sosoYazi{
        display:none;
      }

    }
  }

  /* End of Footer Menu*/


  

  /***

    SignUp Page

  ***/  

  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      // height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;
    bottom: 10px;
    padding-left: 3%;
    padding-right: 3%;
    justify-content: space-between;
    margin-top: 50px;
    padding-bottom: 40px;  
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1919px){
  
  #mainID{
    // padding-left:150px;
    // padding-right:150px;
  }  

  #navID{
    padding-left:130px;
    padding-right: 130px;
  }
  .sliderID{
    padding-left:130px;
    padding-right: 130px;
  }

  .sliderWrapper{
    height:595px;
    background-size: cover;
    background-position: bottom;

    .sliderQuote{
      width:430px;
      font-size:40px;
    }


  }
  .filterSectionWrapperID{
    padding-left:130px;
    padding-right: 130px;
  }  

  #hakkindIDWrapper{
    padding-left:130px;
    padding-right: 130px;    
  }
  #makalelerIDWrapper{
    padding-left:130px;
    padding-right: 130px;    
  }
  #egitimlerIDWrapper{
    padding-left:130px;
    padding-right: 130px;    
  }

  /* Home Page*/
  .filterSectionWrapper{
    min-height: 330px !important;
    // padding-bottom:60px;
  }

 


  .aboutlayerSectionWrapper{

    .webSectionTitle{
      margin-top:0px !important;
    }

  }

  /***

    SignUp Page

  ***/  

  
  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%; 
    display: flex;
    box-sizing: border-box;
    position:fixed;
    bottom:10px;
    padding-left:3%;
    padding-right:3%;     
    justify-content: space-between;    
  }
}
@media only screen and (min-width: 1920px){

  #mainID{
    // padding-left:150px;
    // padding-right:150px;
  }  

  #navID{
    padding-left:150px;
    padding-right: 150px;
  }
  .sliderID{
    padding-left:150px;
    padding-right: 150px;
  }

  .sliderWrapper{
    height:595px;

  }
  .filterSectionWrapperID{
    padding-left:150px;
    padding-right: 150px;
  }  

  #hakkindIDWrapper{
    padding-left:150px;
    padding-right: 150px;    
  }
  #makalelerIDWrapper{
    padding-left:150px;
    padding-right: 150px;    
  }
  #egitimlerIDWrapper{
    padding-left:150px;
    padding-right: 150px;    
  }

  .mainbg{
    
  }



    /* Home Page*/
    .filterSectionWrapper{
      min-height: 340px !important;
      padding-bottom: unset !important;
      // padding-bottom:60px;
    }
  
  /***

    SignUp Page

  ***/  

  
  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%; 
    display: flex;
    box-sizing: border-box;
    position:fixed;
    bottom:10px;
    padding-left:3%;
    padding-right:3%;     
    justify-content: space-between;    
  }
}

@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    

    main.signup{
      overflow-y: auto;
    }

  .contentInfoWrapper.gorus-konular.country-city{
    height: 100% !important;
  }


    /*Nav Bar*/

    .navsubbarhome {
      background:#fff;
      height: 97px;
      width: 100%;
      display: block;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      font-size: 1.4rem;
      position: fixed;
      top: 0;
      z-index:2;
      
      padding-bottom:15px;
      transition: all 0.5s ease;   
      
    }    

    /*End of Top */


    /* SignUp */

    .componentWrapper.step2-layer1 .contentInfoWrapper{
      padding-top:20px;
    }

    .checkContainer{
      margin-bottom:35px;
    }

    .react-dropdown-select-input {
      margin-top:-5px !important;  
    }

    .mainSection3 .contentSpacer{
      margin-top:5px !important;
    }


    .baslangicWrapper{
      display: unset;
    }

    .btnWeb.btnBasla{
      -webkit-appearance:"none";
      text-align: center;
      margin-right: auto;
      margin-left:auto;
    }

  }
}
