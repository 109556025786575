.navsubbarhome {
  background:#fff;
  height: 200px;
  width: calc(100% - 18px);
  display: block;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  font-size: 1.4rem;
  position: fixed;
  top: 0;
  z-index:2;
  
  padding-bottom:15px;
  // transition: all 0.5s ease;   
  
}

.navsubbarhome-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 100%;
  background:unset !important;
}

.navsubbarhome-logo {
  justify-self: start;
  // margin-left: 20px;
  max-width: 332px;
  cursor: pointer;
  height: auto;
  max-height: 150px;
  width:100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  // transition: all 0.5s ease; 
  // transition: all 0.5s ease; 
  // box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.25);
  // border-radius: 50%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.navsub-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  width: 100%;
  justify-content: end;
  padding-right:70px
}


.navsub-item {
  height: 40px;
  display: flex;
}

.rightMenuButton{
  position: absolute;
  border:none;
  background:none;
  align-self: center;
  justify-self: center;
  outline: none;
  right:23px;
  width:30px;
  height: 30px;
  opacity: 1;
  transition: all 0.5s ease; 
  font-size:1.8rem;
  color:#8b8a8f;

  &:hover{
    cursor:pointer;
    color:#384a60;
  }

  &.hide{
    opacity: 0;
    transition: all 0.5s ease; 
  }

}


.fa-times{
  color: #384a60;
}

.fa-bars {
  color: #384a60;
}

.navsub-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  z-index:1;
}

.navsub-menu-sub {
  position: absolute;
  display: flex;
  grid-template-columns: repeat(3, auto);
  grid-gap: 4px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  max-width: 400px;
  width:100vw;
  top:27px;
  right:30px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom:0px;  
  
}

.navsub-item-sub {
  font-family: "Montserrat", sans-serif;
  color:#c2b8b1;

  &:nth-of-type(3){
    font-family: "Montserrat", sans-serif;
    color:#fff !important;
    font-weight: normal;
    font-size:18px;
    margin-left:70px;

  }
}

.navsubbarhome.smaller{
  height:80px;
  // transition: all 0.5s ease; 
  box-shadow: 0 5px 5px rgba(116,116,116,.25);
  background: rgba(255,255,255,0.9);

  & .navsubbarhome-logo{
    max-height:70px;
    top:-40px;
    // transition: all 0.5s ease; 
    
  }

  & .navsubbarhome-container {
    height:20px;
  }

  & .navsub-menu-sub {
    top:5px;
  }

}

.navsub-item-for-mobile{
  display:none;
}

.navsubmenuCloseBtn{
  background:url('/assets/images/close-button-white.png');
  width:38px;
  height:38px;
  display:block;
  position:absolute;
  top:42px;
  right:31px;
  border:none;
  outline:none;
  cursor:pointer;
}

.navsub-menu-sub-left {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 4px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  max-width: 200px;
  width:100vw;
  top:27px;
  left:0px;
  justify-content: start;
  z-index:1;
  margin-bottom:0px;
}

.navsub-item-sub-left {
  position:relative;
  font-family: 'Roboto Condensed', sans-serif;
  color:#c2b8b1;
  margin-bottom:0px;
  left:80px;
  align-items: center;  

  &:nth-of-type(3){
    font-family: 'Roboto Condensed', sans-serif;
    color:#FF004B !important;
    font-weight: bold;

  }

}


.img-katilim{
  width:33px;
  height:33px;
}

.img-bilgilerim{
  width:33px;
  height:33px;
  margin-top:-5px;
}

.langbg{
  background:url('/assets/images/langbg.png');
  width:50px;
  height:41px;
  line-height: 41px;
}

.logoContainer{
  background: white;
  top: 17px;
  position: relative;
  margin-bottom:15px;
}
.linka{
  font-family: 'Roboto Condensed', sans-serif;
  color: #c2b8b1 !important;


}
.btnCloseSubPage{
  
  background: none;
  border:none;  

  &:hover{
    cursor: pointer;
  }

  img{
    position:relative;
    right:-20px;
    top:unset;
  }


}

.articleMenuWrapper{
  left:0px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 25px;
  left: 13px;
  top: 12px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  // background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top:0px;
}

/* General sidebar styles */
.bm-menu {
  background: #3e495f;
  padding: 30px 0px 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-size:17px;
  color:#fff;
  text-align: left;
  border-bottom:1px dashed #fff;
  padding-top:5px;
  padding-bottom:5px;

  &.selected{
    color:#FF004B;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top:0px;
}
.menuButton{
  background:none;
  border:none;

  span{
    position: relative;
    font-family: 'Roboto Condensed', sans-serif;
    color: #c2b8b1;
    margin-bottom: 0px;
    left: 10px;
    align-items: center;    
  }

}