.aboutSectionWrapper{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin:0px;
  padding:0px;
  padding-bottom:0px;
  background:url('../../../assets/images/bottombg.png');
  background-size:contain;
  background-position: center 200px;
  background-repeat: no-repeat;
  // margin-top:100px;
  // margin-bottom: 100px;

  &::before{
    content: ' ';
    width: 300px;
    height: 400px;
    left:0px;
    position:fixed;
    
    background-size: cover;    
  }


  .webSectionTitle{
    font-family: 'Baskerville BT',serif;
    font-weight: normal;
    font-size:52px;
    color:#384a5f;
    margin-bottom:0px;
  }


  .aboutRow{
    display:flex;
    width: 100%;
    position:relative;
    justify-content: center;

    &:nth-of-type(2){
      // height:570px;
    }

  }

  .aboutGridWrapper{
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows: 1fr;   
    width: 100%;
    height: 100%; 
    max-width: 1336px;   

  }

  .aboutSecondRow{
    .aboutGridWrapper{
      max-width: 100%;
    }
  }

  .aboutIntro{
    display:flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;

    p{
      font-weight: normal;
      color: #363636;
      font-size: 1.3rem;
      line-height: 1.7rem;
      font-family: "Baskerville BT", serif;
      padding:0px;
      padding-left:40px;
      padding-right:40px;
      margin-bottom:30px;
      text-align: center;

    }

  }

  .aboutIntroSecond{
    display:flex;
    width: 100%;
    flex-direction: column;
    height: 100%;

    p{
      font-weight: normal;
      color: #c2b7b1;
      font-size: 1.1rem;
      line-height: 1.7rem;
      font-family: "Baskerville BT", serif;
      padding:0px;
      padding-left:40px;
      padding-right:40px;
      margin-bottom:15px;
      max-width: 680px;

      &:last-of-type{
        margin-top:10px;
      }

      span{

        &:last-of-type{
          margin-right:20px;
        }
      }

    }
    .supporters{
      display:flex;
      align-items: center;
      padding-left:30px;
      padding-right:30px;

      h5{
        margin-left:30px;
        margin-right:30px;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }

  }



  .aboutImage{
    background:url('/assets/images/kadinlar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 5px;
  }  

  .aboutImageSecond{
    background:url('/assets/images/meslekler.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }  

  .aboutIntroLastP{
    margin-bottom:11px !important;
  }



  .btnShowOzgecmis{
    display: flex;
    justify-content: center;
    align-items: center;
    background:none;
    border:none;
    outline: none;
    color:#fff;
    cursor: pointer;
    font-weight: normal;
    width:400px;
    height:50px;
    font-size: 1.3rem;
    text-align: center;
    font-family: "Baskerville BT", serif;
    background:rgba(78,81,90,0.95);
    transition: background 0.3s;
    &:hover{
      color:rgba(78,81,90,1);
      background:rgba(255,255,255,0.9);
    }
  }

  .textcenter{
    text-align: center;
  }

}

.ozgecmisWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom:20px;
  margin-top:80px;
 
  .ozgecmisTitle{
    font-family: "Baskerville BT", serif;
    font-size:1.3rem;
    color:#4e515a;
    border-bottom:2px solid #4e515a;
    width: 100%;
    max-width: 400px;
    text-align: center;
    padding-bottom:10px;
    margin-bottom:20px;
  }

}

