.searchSectionWrapper{
  display: flex;
  background:#efeded;
  width: 100%;
  height:100vh;
  padding-top:191px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  


  .webSectionTitle{
    margin-top:0px;
    margin-bottom:20px;
  }

  .searchWrapper{
    display:grid;
    grid-template-columns:300px 1fr;
    grid-template-rows: 1fr;   
    grid-gap:30px;
    width: 100%;
    height: 100%; 
    max-width: 1336px;   
    padding:30px;
    font-size:1.3rem;
    
  }

  .searchContent{
    p{
      color:#384a5f;
      font-size:17px;
      font-family: 'Baskerville BT',serif;
  
    }
    
  }


  .searchTitle{
    color:#384a5f;
    font-size:24px;
  }

  .ulsearchList{
    list-style: none;
    font-size:1.3rem;
    max-width: 200px;
    justify-self: center;

    li{
      margin-bottom: 10px;
      color:#384a5f;
      font-weight: 500;
      font-size: 17px;
      font-family: 'Baskerville BT',serif;
      line-height: 17px;     
      text-align: center; 

      &.searchFilterTitle{
        color:#384a5f;
        font-weight: 500;
        font-family: 'Baskerville BT',serif;
        line-height: 17px;    
        font-size: 17px;    
      }

    }


    .searchFilterContent{
      .inputWrapper.web{
        div{
          &:first-of-type{
            width:100%;
          }
        }
      }
    }


  }

}
.searchListSticky{
  position:sticky;
  top:30px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .editsfilter{
    margin-top:0px;
  }

  .editfiltericon {
    position: absolute;
    left: 14px;
    top: 8px;
  }

  .buttonwrapper{
    width: auto !important;

    .btnFilterSearch{

      border-radius: 0;
      background:#384a60;
      color:#fff;
      &:hover{
        cursor:pointer;
        background: #586a80;
      }
    }
  }

  .react-dropdown-select {
    position: relative;
    margin-top: 0px;
    height: 40px;
    
    border: 1px solid #384a5f;
    outline-color: #000 !important;
    max-width: 300px;
    width: 100%;
    font-weight: 300;
    font-size: 15px;
    font-family: 'Baskerville BT',serif;
    background:#fff;
    
  }

  .react-dropdown-select-dropdown{
  }  
  .react-dropdown-select-type-single{
    font-size:12px;
  }
  .react-dropdown-select-input{
    position:absolute;
    top:5px;
    width: 100%;

    ::placeholder{
      font-size:15px;
    }
  }

  div[class*="ContentComponent"]{
    padding-left:5px;
    padding-right: 5px;
  }
        

}

.expertCount{
  font-weight: bold;
}
.searchResultInfoText{
  font-family: 'Baskerville BT',serif;
  font-size:16px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  

  li{
    display:inline;

    a,span{
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #384a5f !important;
      text-decoration: none;
      // background-color: #fff;
      // border: 1px solid #ddd;     
      
      &:hover{
        color:#586a80;
      }
    }

    &:first-child>a,&:first-child>span{
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;      
    }

    &:last-child>a,&:last-child>span{
      margin-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }    

  }
}
.lastExpertsContentWrapper{
  display:grid;
  // grid-template-columns: repeat(5,auto);
  // grid-template-columns: repeat(auto-fill, minmax(min(100px,200px), 1fr));
  grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
  grid-gap: 28px;
  padding-top:30px;




  .lastExpertCard{
    position: relative;

    .lastExpertImg{
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.20);
      margin-bottom:5px;
      width: 100%;
      height: auto;
      stroke: #384a5f;
    }

    .lastExpertName{
      color:#384a5f;
      font-size:19px;
      font-family: 'Baskerville BT',serif;
      font-weight: bold;
      margin-bottom:5px;
      text-align:center;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;      

    }
    .lastExpertTitle{
      color:#586a80;
      font-family: 'Baskerville BT',serif;
      font-weight: normal;
      font-style: italic;
      font-size: 16px;
      min-height: 50px;
      text-align:center;
    }


    .expertButtonWrapper{
      display:flex;
      justify-content: center;
    }

    .lastExpertDetailBtn{
      // margin-top:20px;
      font-family: 'Baskerville BT',serif;
      padding-top: 6.5px;
      padding-bottom: 6.5px;      
      border-radius: 0;
      background:#384a5f;

      &:hover{
        background:#586a80 !important;
        color:#fff;
        transition: all 0.3s ease-out;
        cursor:pointer;
      }

    }
    
  }

}



.paginationWrapper{
  display:flex;
  justify-content: center;
  margin-top:20px;

  .disabled{
    color:lightgrey; 
  }

}