.articlePageTitle{
  color:#FF004B;
  margin-bottom: 0px;
  font-size:24px;
}

.writerWrapper{
  margin:0px;
  padding:0px;
  font-style: italic;
  margin-top:-5px;
  font-size:15px;
}

.articlePageText{
  position: relative;
  color: #c2b7b1;
  font-family: 'Roboto',sans-serif;
  padding-bottom:50px;
  
  .articleIntro{
    font-size:17px;
    font-family: 'Roboto',sans-serif;
    margin-top:20px;
    margin-bottom:20px;
    font-weight: 500;
    color: #c2b7b1;
  }

  .articlePageTextP{
    font-size:17px;
    font-family: 'Roboto',sans-serif;
    color: #c2b7b1;    

    i{
      color: #c2b7b1;    
      
    }


  }
  .articleContentImgWrapper{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img{
      border-radius: 15px;
      justify-self: center;
      max-width: 800px;
    }
    span{
      font-size:15px !important;
      font-family: 'Roboto',sans-serif;
      color: #c2b7b1;          
    }
    a{
      font-size:15px !important;
      font-family: 'Roboto',sans-serif;
      color: #c2b7b1;          
    }

  }  

  .articlePageSubTitle{
    color:#FF004B;
    margin-bottom: 0px;
    font-size:21px;
  }

  hr{
    margin-top:10px;
    margin-bottom:10px;
    background-color:#c2b7b1;
    border:none;
    height: 1px;
  }
  
  sup{
    color:#FF004B;
  }

  .referrerText{
    font-size:14px;
    color:#c2b7b1;
    font-weight: normal;

  }

  .referrerlink{
    font-size:14px;
    color:#c2b7b1;
    font-weight: normal;
    text-decoration: underline;

    &:hover{
      color:#FF004B;
    }
  }
  
  .linkOpen{
    color:#c2b7b1;
    text-decoration: underline;
    
    &:hover{
      color:#FF004B;
    }
  }

}
