.baslangicWrapper{
  display:flex;
  justify-content: center;
  max-width: 1300px;
  width: 100%;
  position:relative;
  flex-direction: column;
  padding-bottom:40px;


  p{
    font-family: 'Baskerville BT',serif !important;
    font-size: 1.1rem !important;

    strong{
      font-family: 'Baskerville BT',serif !important;
    }

    u{
      font-family: 'Baskerville BT',serif !important;

    }


  }

  .btnBasla{
    max-width: 200px;
    justify-content: center;
    align-self: center;
    margin-top:20px;
    padding: 0px;
    width: 107px;
    height: 56px;   
    position: absolute;
    bottom: 0px;
    z-index: 2;     
    border-radius: 0;
    background:#384a60;

    &:hover{
      cursor: pointer;
      background:#586a80 !important;
      color:#fff;
    }
  }

}

